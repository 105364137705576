<template>
    <div class="ph-container">
        <v-container fluid class="ph-nonex-container">
            <v-row class="ma-3">
                
                <v-col>
                    <v-select
                        class="mx-5" style="float:right"
                        :items="items"
                        v-model="defaultItem"
                        label="Reactivation Approval Scope"
                        dense
                        outlined
                    ></v-select>
                </v-col>

                <v-col cols="12" v-if="defaultItem == 'Entity'">

                    <v-col>
                        <h3 class="mx-5">Reactivation Approval Per Entity</h3>
                    </v-col>

                    <v-card elevation="1" class="ph-nonex-datatable-container">
                        <v-data-table
                            :headers="entityHeaders"
                            :items="entity"
                            :calculate-widths="true"
                            class="ph-nonex-datatable"
                            :search = "search"
                            >
                            <template v-slot:top>
                                <v-toolbar flat color="white">
                                    <v-layout>
                                        <v-spacer></v-spacer>
                                        <v-flex md4 class="search">
                                            <v-text-field
                                                v-model="search"
                                                placeholder="Search"
                                                label="Search"
                                                single-line
                                                hide-details
                                            ></v-text-field> 
                                        </v-flex>
                                    </v-layout>
                                </v-toolbar>
                            </template>
                            <template v-slot:[`item.sp_primary_details.account_name`]="{item}">
                                <div style="cursor: pointer;" @click="$router.push({ path: 'sales-prospecting/view-profile/' + item.id })">
                                    {{ item.sp_primary_details.account_name }}
                                </div>
                            </template>
                            <template v-slot:[`item.created_at`]="{item}">
                                {{formatDate(item.created_at)}}
                            </template>
                            <template v-slot:[`item.action`]="{item}">
                                <v-btn
                                    text
                                    color="primary"
                                    @click="dialog = true; itemId = item.id; approvalItem = item"
                                    >
                                    <v-icon>mdi-checkbox-marked-outline</v-icon>
                                </v-btn>
                            </template>
                        </v-data-table>
                    </v-card>
                </v-col>
            </v-row>

            <v-row class="ma-3" v-if="defaultItem == 'Location'">

                <v-col>
                    <h3 class="mx-5">Reactivation Approval Per Location</h3>
                </v-col>


                <v-col cols="12">
                    <v-card elevation="1" class="ph-nonex-datatable-container">
                        <v-data-table
                            :headers="locationHeaders"
                            :items="locations"
                            :calculate-widths="true"
                            class="ph-nonex-datatable"
                            :search = "search"
                            >
                            <template v-slot:top>
                                <v-toolbar flat color="white">
                                    <v-layout>
                                        <v-spacer></v-spacer>
                                        <v-flex md4 class="search">
                                            <v-text-field
                                                v-model="search"
                                                placeholder="Search"
                                                label="Search"
                                                single-line
                                                hide-details
                                            ></v-text-field> 
                                        </v-flex>
                                    </v-layout>
                                </v-toolbar>
                            </template>
                            <template v-slot:[`item.sp_primary_details.account_name`]="{item}">
                                <div style="cursor: pointer;" @click="$router.push({ path: 'sales-prospecting/view-profile/' + item.id })">
                                    {{ item.sp_primary_details.account_name }}
                                </div>
                            </template>
                            <template v-slot:[`item.created_at`]="{item}">
                                {{formatDate(item.created_at)}}
                            </template>
                            <template v-slot:[`item.action`]="{item}">
                                <v-btn
                                    text
                                    color="primary"
                                    @click="dialog = true; itemId = item.id; approvalItem = item"
                                    >
                                    <v-icon>mdi-checkbox-marked-outline</v-icon>
                                </v-btn>
                            </template>
                        </v-data-table>
                    </v-card>
                </v-col>
            </v-row>
            
            <v-dialog
            v-model="dialog"
            width="900px"
            persistent
            >
                <v-card>
                    <v-card-title>
                    <span class="text-h5" id="dialog_title">File Reactivation</span>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col>
                                    <h4>Supporting Documents</h4>
                                </v-col>
                            </v-row>

                            <v-row>

                                <v-list v-if="defaultItem == 'Entity'">
                                    <v-list-item v-for="(item, index) in approvalItem.entity_activation.reactivation_documents" :key="index">
                                        <v-list-item-content>
                                            {{item.title}}
                                        </v-list-item-content>
                                        <v-list-item-action>
                                            <v-icon @click="retrieveUploaded(item.url)">
                                                mdi-eye
                                            </v-icon>
                                        </v-list-item-action>
                                    </v-list-item>
                                </v-list>
                                <v-list v-else>
                                    <v-list-item v-for="(item, index) in approvalItem.branch_activation.reactivation_documents" :key="index">
                                        <v-list-item-content>
                                            {{item.title}}
                                        </v-list-item-content>
                                        <v-list-item-action>
                                            <v-icon @click="retrieveUploaded(item.url)">
                                                mdi-eye
                                            </v-icon>
                                        </v-list-item-action>
                                    </v-list-item>
                                </v-list>

                            </v-row>
                            
                            <v-row>
                                <v-col>
                                    <h4>Reason for Reactivation</h4>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col v-if="defaultItem == 'Entity'">
                                    <v-textarea
                                        label="Remarks"
                                        auto-grow
                                        readonly
                                        outlined
                                        v-model="approvalItem.entity_activation.reactivation_remarks"
                                    ></v-textarea>
                                </v-col>
                                <v-col v-else>
                                    <v-textarea
                                        label="Remarks"
                                        auto-grow
                                        readonly
                                        outlined
                                        v-model="approvalItem.branch_activation.reactivation_remarks"
                                    ></v-textarea>
                                </v-col>
                            </v-row>

                        </v-container>
                    </v-card-text>

                    <v-card-actions>

                    <v-btn
                        style="float:left"
                        outlined color="primary"
                        text
                        @click="dialog = false; clearDialog()"
                    >
                        Cancel
                    </v-btn>

                    <v-spacer></v-spacer>
                    
                    <v-btn
                        outlined color="red" @click="dialog2 = true"
                    >
                        Disapprove
                    </v-btn>
                    <v-btn color="success" @click="approve">
                        Approve
                    </v-btn>

                    </v-card-actions>
            </v-card>
        </v-dialog>



        <v-dialog
            v-model="dialog2"
            width="600px"
            persistent
            >
                <v-card>
                    <v-card-title>
                    <span class="text-h5" id="dialog_title">Disapprove</span>
                    </v-card-title>
                    <v-card-text>
                        <v-container>  

                            <v-row>
                                <v-col>
                                    <h4>Reason for Disapproval</h4>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col>
                                    <v-textarea
                                        label="Remarks"
                                        auto-grow
                                        outlined
                                        v-model="comment"
                                    ></v-textarea>
                                </v-col>
                            </v-row>

                        </v-container>
                    </v-card-text>

                    <v-card-actions>

                    <v-btn
                        style="float:left"
                        outlined color="primary"
                        text
                        @click="dialog2 = false"
                    >
                        Cancel
                    </v-btn>

                    <v-spacer></v-spacer>
    
                    <v-btn
                        v-if="comment != ''"
                        outlined color="red" @click="disApprove"
                    >
                        Disapprove
                    </v-btn>
                    <v-btn
                        v-else
                        disabled
                    >
                        Disapprove
                    </v-btn>
                    </v-card-actions>
            </v-card>
        </v-dialog>


        </v-container>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { parseISO, format } from 'date-fns'
export default {
  name: 'Users',
  data () {
    return {
        search: '',
        entity: [],
        entityHeaders: [
            {
                text: 'Account Name',
                sortable: true,
                value: 'sp_primary_details.account_name'
            },
            {
                text: 'BP ID Number',
                sortable: true,
                value: 'bp_idno'
            },
            {
                text: 'SP Number',
                sortable: true,
                value: 'prospective_number'
            },
            {
                text: 'Owner',
                sortable: true,
                value: 'created_by'
            },
            {
                text: 'Status',
                sortable: true,
                value: 'account_status'
            },
            {
                text: 'Action',
                sortable: false,
                value: 'action',
                align: 'center'
            }
        ],
        locations: [],
        locationHeaders: [
            {
                text: 'Branch Name',
                sortable: true,
                value: 'branch_name'
            },
            {
                text: 'Branch ID',
                sortable: true,
                value: 'id'
            },
            {
                text: 'Location Code',
                sortable: true,
                value: 'location_code'
            },
            {
                text: 'SP Number',
                sortable: true,
                value: 'sales_prospects_id'
            },
            {
                text: 'Status',
                sortable: true,
                value: 'account_status'
            },
            {
                text: 'Action',
                sortable: false,
                value: 'action',
                align: 'center'
            }
        ],
        defaultItem: 'Entity',
        items: ['Entity','Location'],
        dialog: false,
        dialog2: false,
        itemId: '',
        comment: '',
        approvalItem: {
            entity_activation : {
                reactivation_documents : null,
                remarks: ''
            },
            branch_activation : {
                reactivation_documents : null,
                remarks: ''
            },
        }
    }
  },
  computed: {
    ...mapGetters({
      currUser: 'auth/currUser'
    })
  },
  async mounted () {
    this.assignData()
  },
  methods: {
    async assignData(){
        this.entity = []
        this.locations = []

        const res = await this.$store.dispatch('salesProspecting/doGetReactivationList')
        res.data.result.entity.map(e => {
            if (e.account_status == 'Reactivation Pending (Final)'){

                if (e.entity_activation.reactivation_documents != null) {
                    e.entity_activation.reactivation_documents = JSON.parse(e.entity_activation.reactivation_documents)
                }

                this.entity.push(e)
            }
        })

        res.data.result.location.map(e => {
            if (e.account_status == 'Reactivation Pending (Final)'){
                if (e.branch_activation.reactivation_documents != null) {
                    e.branch_activation.reactivation_documents = JSON.parse(e.branch_activation.reactivation_documents)
                }
                this.locations.push(e)
            }
        })
    },
    formatDate (date) {
        return format(parseISO(date), 'yyyy-MM-dd')
    },
    async approve () {
        if (this.defaultItem == 'Entity'){
            const res = await this.$store.dispatch('salesProspecting/doApproveEntity', this.itemId)
            if (res.status == 200){
                this.$store.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Approved Successfully!' ,'messagetype':'success'},{ root: true });
                this.dialog = false
                this.clearDialog()
                this.assignData()
            } else {
                this.$store.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Something Went Wrong!' ,'messagetype':'error'},{ root: true });
            }
        } else {
            const res = await this.$store.dispatch('salesProspecting/doApproveLocation', this.itemId)
            if (res.status == 200){
                this.$store.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Approved Successfully!' ,'messagetype':'success'},{ root: true });
                this.dialog = false
                this.clearDialog()
                this.assignData()
            } else {
                this.$store.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Something Went Wrong!' ,'messagetype':'error'},{ root: true });
            }
        }
    },
    async disApprove () {
        if (this.defaultItem == 'Entity'){
            const payload = {
                id : this.itemId,
                payload: {
                    disapproved_remarks: this.comment
                }
            }
            const res = await this.$store.dispatch('salesProspecting/doDisapproveEntity', {payload})
            if (res.status == 200){
                this.$store.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Disapproved Successfully!' ,'messagetype':'success'},{ root: true });
                this.dialog = false
                this.clearDialog()
                this.assignData()
            } else {
                this.$store.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Something Went Wrong!' ,'messagetype':'error'},{ root: true });
            }
        } else {
            const payload = {
                id : this.itemId,
                payload: {
                    disapproved_remarks: this.comment
                }
            }
            const res = await this.$store.dispatch('salesProspecting/doDisapproveLocation', {payload})
            if (res.status == 200){
                this.$store.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Disapproved Successfully!' ,'messagetype':'success'},{ root: true });
                this.dialog = false
                this.clearDialog()
                this.assignData()
            } else {
                this.$store.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Something Went Wrong!' ,'messagetype':'error'},{ root: true });
            }
        }
    },
    clearDialog(){
        this.itemId = '',
        this.comment = '',
        this.dialog2 = false,
        this.approvalItem=  {
            entity_activation : {
                reactivation_documents : null,
                remarks: ''
            },
            branch_activation : {
                reactivation_documents : null,
                remarks: ''
            },
        }
    },
    async retrieveUploaded (item) {
        const payload = {
            file: item
        }
        const res = await this.$store.dispatch('salesProspecting/doRetrieveUploaded', payload)
        console.log(res)
        var extension = res.data.result.extension
        var file = Buffer.from(res.data.result.file, 'base64')
        var blob = ''
        if (extension === 'pdf') {
            blob = new Blob([file], { type: 'application/pdf' })
        } else if (extension === 'jpg') {
            blob = new Blob([file], { type: 'image/jpg' })
        } else if (extension === 'png') {
            blob = new Blob([file], { type: 'image/png' })
        } else if (extension === 'jpeg') {
            blob = new Blob([file], { type: 'image/jpeg' })
        }
        var fileURL = URL.createObjectURL(blob)
        window.open(fileURL, '_blank')
    }
  }
}
</script>
